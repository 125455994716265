var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loadingAction.getAssetAnalytics.accountHeatmap ? _c('Spinner', {
    staticClass: "push"
  }) : _c('div', [_c('div', {
    staticClass: "table table-borderless table-sm heatmap push"
  }, [_c('thead', [_c('tr', [_c('th'), _vm._l(_vm.monthlyInvoiceData, function (item, index) {
    return _c('th', {
      key: index
    }, [_c('div', {
      staticClass: "heatmap-header"
    }, [_vm._v(_vm._s(_vm._f("date")(item.date, 'MMM YYYY')))])]);
  })], 2)]), _c('tbody', [_c('tr', [_c('td', [_c('p', {
    staticClass: "font-size-h4 font-w600 mb-0"
  }, [_c('i', {
    staticClass: "fa fa-file-invoice fa-fw"
  }), _vm._v(" Invoices")])]), _vm._l(_vm.monthlyInvoiceData, function (item, index) {
    return _c('td', {
      key: index,
      ref: "tooltip",
      refInFor: true,
      staticClass: "heatmap-cell",
      class: {
        'heatmap-cell--danger': item.coverage < 50,
        'heatmap-cell--success': item.coverage === 100,
        'heatmap-cell--warning': item.coverage >= 50 && item.coverage < 100 || item.coverage > 100
      },
      attrs: {
        "title": `${item.coverage.toFixed(0)}%`
      },
      on: {
        "click": function ($event) {
          return _vm.onClickMonth(item.date);
        }
      }
    }, [_vm.isSelectedMonth(item.date) ? _c('i', {
      staticClass: "fa fa-times text-white"
    }) : _vm._e()]);
  })], 2)])]), _c('SectionTitle', [_vm._v(_vm._s(_vm.drilldownMonth))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-6"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.detailedData,
      "no-paging": true,
      "columns": [{
        code: 'date',
        name: 'Date',
        sortable: false
      }, {
        code: 'coverage',
        name: 'Coverage',
        sortable: false
      }, {
        code: 'invoiceNumbers',
        name: 'Invoice Number(s)',
        sortable: false
      }]
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function (_ref) {
        var item = _ref.data;
        return [_c('strong', [_vm._v(_vm._s(_vm._f("date")(item.date)))])];
      }
    }, {
      key: "coverage",
      fn: function (_ref2) {
        var item = _ref2.data;
        return [item.invoices.length === 1 ? _c('span', {
          staticClass: "text-success"
        }, [_c('i', {
          staticClass: "fa fa-check-circle fa-fw"
        })]) : item.invoices.length === 0 ? _c('span', {
          staticClass: "text-danger"
        }, [_c('i', {
          staticClass: "fa fa-times-circle fa-fw"
        })]) : _c('span', {
          staticClass: "text-warning"
        }, [_c('i', {
          staticClass: "fa fa-exclamation-circle fa-fw"
        })])];
      }
    }, {
      key: "invoiceNumbers",
      fn: function (_ref3) {
        var item = _ref3.data;
        return [_vm._v(" " + _vm._s(item.invoices.map(function (inv) {
          return inv.values.invoiceNumber;
        }).join(', ')) + " ")];
      }
    }])
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }